import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type {
	AddRecommendedDecisionMakerMutation,
	AddRecommendedDecisionMakerMutationVariables,
} from './addRecommendedDecisionMaker.graphql';
import { AddRecommendedDecisionMaker } from './addRecommendedDecisionMaker.graphql';
import { ERROR_MAP } from './constants';

type RecommendedDecisionMaker = {
	normalizedId: string;
	canonicalPetitionTargetId: string | null;
	name: string;
	type: 'PERSON' | 'ORG';
	title: string | null;
	photoUrl: string | null;
};

type AddRecommendedDecisionMakerParams = {
	petitionId: string;
	recommendedDecisionMaker: RecommendedDecisionMaker;
} & { trackingContext: string };

type AddRecommendedDecisionMakerResponse = Extract<
	AddRecommendedDecisionMakerMutation['addDecisionMakerToPetition'],
	{ __typename: 'AddDecisionMakerToPetitionSuccess' }
>;

export async function editPetitionDecisionMakers(
	{ petitionId, recommendedDecisionMaker, trackingContext }: AddRecommendedDecisionMakerParams,
	{ gql: { fetch }, errorReporter }: UtilityContext,
): Promise<AddRecommendedDecisionMakerResponse> {
	const petitionInput = {
		id: petitionId,
		decisionMaker: {
			id: recommendedDecisionMaker.canonicalPetitionTargetId,
			displayName: recommendedDecisionMaker.name,
		},
	};
	const { data, errors } = await fetch<
		AddRecommendedDecisionMakerMutation,
		AddRecommendedDecisionMakerMutationVariables
	>({
		query: AddRecommendedDecisionMaker,
		variables: {
			input: petitionInput,
		},
		rejectOnError: false,
	});

	if (errors) throw new Error(ERROR_MAP.GRAPHQL_ERROR);

	if (!data?.addDecisionMakerToPetition) {
		const error = new Error(ERROR_MAP.NO_DATA);
		void errorReporter.report({ error, params: { context: trackingContext } });
		throw error;
	}

	if (data.addDecisionMakerToPetition.__typename === 'AddDecisionMakerToPetitionError') {
		void errorReporter.report({
			error: new Error('DM Recommender modal error'),
			params: { context: trackingContext, errorType: data.addDecisionMakerToPetition.type },
		});
		throw new Error(ERROR_MAP[data.addDecisionMakerToPetition.type ?? 'UNKNOWN_ERROR']);
	}

	return data.addDecisionMakerToPetition;
}
