import React, { useRef } from 'react';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { Button, FakeLink } from '@change-corgi/design-system/components/actions';
import { InlineMessage } from '@change-corgi/design-system/components/alerts';
import { Card } from '@change-corgi/design-system/components/content';
import { Icon } from '@change-corgi/design-system/components/icon';
import { Avatar } from '@change-corgi/design-system/components/media';
import { iconApartmentRounded, iconCheck, iconPersonStar } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import { isError, isLoaded, isLoading } from 'src/app/shared/utils/async';

export type RecommendedDecisionMaker = {
	normalizedId: string;
	canonicalPetitionTargetId: string | null;
	name: string;
	type: 'PERSON' | 'ORG';
	title: string | null;
	photoUrl: string | null;
};
type Status = 'idle' | 'loading' | 'loaded' | 'error';
type Data = RecommendedDecisionMaker;
type Error = { type: 'ADD' | 'REMOVE' };
type RecommendedDecisionMakerState =
	| { data: Data; status: Exclude<Status, 'error'> }
	| { data: Data; status: Extract<Status, 'error'>; error: Error };

type RecommendedDecisionMakerCardProps = {
	state: RecommendedDecisionMakerState;
	onAdd: (selectedDecisionMaker: RecommendedDecisionMaker) => Promise<void>;
	onRemove: (selectedDecisionMaker: RecommendedDecisionMaker) => Promise<void>;
};

export function RecommendedDecisionMakerCard({
	state,
	onAdd,
	onRemove,
}: RecommendedDecisionMakerCardProps): React.JSX.Element {
	const recommendedDecisionMaker = state.data;
	const { translate } = useI18n();
	const addButtonRef = useRef<HTMLButtonElement>(null);
	const removeLinkRef = useRef<HTMLAnchorElement & HTMLButtonElement>(null);

	const defaultIcon = recommendedDecisionMaker.type === 'ORG' ? iconApartmentRounded : iconPersonStar;

	return (
		<Card data-qa="dm-recommender-card" p={16} sx={{ borderColor: 'neutral-grey100' }}>
			{isError(state) ? (
				<InlineMessage variant="error" mb={16}>
					<Translate value={`corgi.components.dm_recommender.dm_card.errors.${state.error?.type.toLowerCase()}`} />
				</InlineMessage>
			) : null}
			<Box sx={{ border: 'none', borderRadius: 0, display: 'inline-flex', alignItems: 'flex-start', gap: 16 }}>
				{recommendedDecisionMaker.photoUrl ? (
					<Avatar alt="avatar" size="s" src={recommendedDecisionMaker.photoUrl} />
				) : (
					<Flex
						sx={{
							alignItems: 'center',
							justifyContent: 'center',
							borderRadius: 'full',
							backgroundColor: 'neutral-grey100',
							minWidth: 48,
							minHeight: 48,
						}}
					>
						<Icon icon={defaultIcon} color="typography-secondary" size={24} />
					</Flex>
				)}
				<Flex margin={4} sx={{ flexDirection: 'column', alignItems: 'flex-start', lineHeight: '1' }}>
					<Text sx={{ fontWeight: 'bolder' }}>{recommendedDecisionMaker.name}</Text>
					{recommendedDecisionMaker.title && (
						<Text size="small" sx={{ fontWeight: 'normal' }}>
							{recommendedDecisionMaker.title}
						</Text>
					)}

					<div aria-live="polite">
						{isLoaded(state) ? (
							<Flex mt={16} sx={{ alignItems: 'center', gap: 16 }}>
								<Flex sx={{ alignItems: 'center', gap: 8 }}>
									<Icon icon={iconCheck} color="secondary-green" size={24} />
									<Text size="small" fontWeight="bold" color="secondary-green">
										<Translate value="corgi.components.dm_recommender.dm_card.success" />
									</Text>
								</Flex>
								<FakeLink
									ref={removeLinkRef}
									color="typography-secondary"
									sx={{ fontSize: 14 }}
									onClick={async () => {
										await onRemove(recommendedDecisionMaker);
										addButtonRef.current?.focus();
									}}
								>
									<Translate value="corgi.components.dm_recommender.dm_card.remove_link" />
								</FakeLink>
							</Flex>
						) : (
							<Button
								ref={addButtonRef}
								mt={16}
								px={16}
								size="small"
								sx={{ minWidth: '87px !important' }}
								onClick={async () => {
									await onAdd(recommendedDecisionMaker);
									removeLinkRef.current?.focus();
								}}
								loading={isLoading(state)}
								aria-label={translate('corgi.components.dm_recommender.dm_card.cta')}
							>
								<Translate value="corgi.components.dm_recommender.dm_card.cta" />
							</Button>
						)}
					</div>
				</Flex>
			</Box>
		</Card>
	);
}
